import React, { Component } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 4rem 0 0;
  text-align: center;
`;

class AboutSection extends Component {
  render() {
    return (
      <Section id="about">
        <div className="container">
          <div className="container--small">
            <h2>Vi arbetar med precision!</h2>
            <p>
              Vi ställer höga krav på både form och funktion. Samtidigt vill vi att slutresultatet ska bli perfekt. Vi
              har alltid kundens krav på kvalitet och önskemål i bakhuvudet.
            </p>
          </div>
        </div>
      </Section>
    );
  }
}

export default AboutSection;
