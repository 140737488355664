/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { device } from '../../theme/BreakPoints';
import One from '../../images/slider/one.jpg';
import Two from '../../images/slider/two.jpg';
import Three from '../../images/slider/three.jpg';
import Four from '../../images/slider/four.jpg';
import Five from '../../images/slider/five.jpg';
import Six from '../../images/slider/six.jpg';
import Seven from '../../images/slider/seven.jpg';

const CustomCarousel = styled.div`
  order: 0;

  @media ${device.laptop} {
    order: ${(props) => props.order};
    max-height: 480px;
  }
`;

class CustomerCarousel extends Component {
  render() {
    const settings = {
      arrows: false,
      arrowsBlock: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
    };

    return (
      <CustomCarousel order={1}>
        <Slider {...settings}>
          <div>
            <img src={Two} alt="Two" />
          </div>
          <div>
            <img src={Three} alt="Three" />
          </div>
          <div>
            <img src={Four} alt="Four" />
          </div>
          <div>
            <img src={Five} alt="Five" />
          </div>
          <div>
            <img src={Six} alt="Six" />
          </div>
          <div>
            <img src={Seven} alt="Seven" />
          </div>
          <div>
            <img src={One} alt="One" />
          </div>
        </Slider>
      </CustomCarousel>
    );
  }
}

export default CustomerCarousel;
