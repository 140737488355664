import React, { useState } from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import { device } from '../../theme/BreakPoints';
import Logo from './Logo';

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s all;

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`;

const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media ${device.laptop} {
    display: none;
  }
`;

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 61px;
    left: ${(props) => (props.open ? '0' : '-100%')};
  }
`;

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};

  &::before,
  &::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  &::before {
    transform: ${(props) => (props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)')};
    top: -10px;
  }

  &::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-top: 4rem;

  @media ${device.laptop} {
    flex-direction: row;
    padding-top: 0;
  }
`;

const NavListItem = styled.li`
  padding: 1rem 0;

  @media ${device.laptop} {
    padding: 0 2rem;
  }
  @media ${device.laptop} {
    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const LinkItem = styled(Link)`
  font-family: ${props => props.theme.fonts.fjalla};
  color: ${props => props.theme.colors.nightRider};
  font-size: 2rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media ${device.laptop} {
    font-size: 1rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

function NavBar() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <div className="container">
      <Navigation>
        <Logo />
        <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
        {navbarOpen ? (
          <Navbox open>
            <NavList>
              <NavListItem>
                <LinkItem to="/#hero" onClick={() => setNavbarOpen(!navbarOpen)}>Hem</LinkItem>
              </NavListItem>
              <NavListItem>
                <LinkItem to="/#services" onClick={() => setNavbarOpen(!navbarOpen)}>Tjänster</LinkItem>
              </NavListItem>
              <NavListItem>
                <LinkItem to="/#about" onClick={() => setNavbarOpen(!navbarOpen)}>Referenser</LinkItem>
              </NavListItem>
              <NavListItem>
                <LinkItem to="/#kontakt" onClick={() => setNavbarOpen(!navbarOpen)}>Kontakt</LinkItem>
              </NavListItem>
            </NavList>
          </Navbox>
        ) : (
          <Navbox>
              <NavList>
                <NavListItem>
                  <LinkItem to="/#hero">Hem</LinkItem>
                </NavListItem>
                <NavListItem>
                  <LinkItem to="/#services">Tjänster</LinkItem>
                </NavListItem>
                <NavListItem>
                  <LinkItem to="/#about">Referenser</LinkItem>
                </NavListItem>
                <NavListItem>
                  <LinkItem to="/#kontakt">Kontakt</LinkItem>
                </NavListItem>
              </NavList>
          </Navbox>
        )}
      </Navigation>
    </div>
  );
}

export default NavBar;
