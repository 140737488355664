// Logo.js

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import { device } from '../../theme/BreakPoints';
import LogoImage from '../../images/logo.svg';

const LogoContainer = styled.div`
  width: 34%;

  a {
    display: flex;
    align-items: center;
  }
`;

const LogoLink = styled(Link)`
  & img {
    height: 45px;
    transition: 0.2s all;
  }
`;

function Logo() {
  return (
    <LogoContainer>
      <LogoLink to="/">
        <img src={LogoImage} alt="Logo" />
      </LogoLink>
    </LogoContainer>
  );
}

export default Logo;
