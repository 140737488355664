import React from 'react';
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { device } from '../../theme/BreakPoints';
import GeoMap from '../../components/Map/Map';
import CustomerCarousel from '../../components/CustomerCarousel/CustomerCarousel';
import One from '../../images/sections/grupnjak.jpg';
import AdressIcon from '../../images/icons/home.svg';
import MailIcon from '../../images/icons/send-mail.svg';
import PhoneIcon from '../../images/icons/mobile-camera.svg';
import CheckIcon from '../../images/icons/check-small.svg';

const fadeInAnimation = keyframes`${fadeIn}`;

const Section = styled.section`
  padding: 4rem 0 10rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  padding-bottom: 0.5rem;

  @media ${device.laptop} {
    grid-template-columns: 50% 50%;
    padding-bottom: 0;
    max-height: 480px;
  }
`;

const BackgroundImage = styled.div`
  background-image: url(${(props) => props.bgImg});
  background-size: cover;
  background-position: center;
  padding-bottom: 18rem;
  animation: 2s ${fadeInAnimation};
  order: 0;
  margin-bottom: 0.5rem;

  @media ${device.tablet} {
    padding-bottom: 30rem;
    order: ${(props) => props.order};
  }
`;
const BlockContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 1rem;

  @media ${device.tablet} {
    padding: 3rem;
  }
`;

const Content = styled.div`
  width: 90%;

  @media ${device.tablet} {
    width: 75%;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 0 0 6.5px 0;
  padding: 0 0 0 26px;
  background: url(${CheckIcon}) no-repeat 0 4px;
`;

const Span = styled.span`
  color: #000;

  &:before {
    background-image: url(${(props) => props.icon});
    height: 30px;
    width: 30px;
    background-size: 30px;
  }

  a {
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SquareSection = () => (
  <Section id="services">
    <div className="container">
      <Grid>
        <BackgroundImage bgImg={One} />
        <BlockContent bgColor="#647EB1" color="white">
          <Content>
            <h3>Om oss</h3>
            <p>
              <strong>Geometer</strong> är ett konsultbolag specialicerad på mätning. Med kompetenta och erfarna
              ingenjörer strävar vi efter bästa möjliga resultat. Vi jobbar långsiktigt och breddar succesivt vår
              kompetens såväl internt som externt. Vi levererar anpassade lösningar från projektering till förvaltning.
            </p>
          </Content>
        </BlockContent>
      </Grid>
      <Grid>
        <CustomerCarousel />
        {/* <BackgroundImage bgImg={Two} order={1} /> */}
        <BlockContent bgColor="#EC6608" color="white">
          <Content>
            <h3>Våra fokus områden</h3>
            <List>
              <ListItem>Byggutsättning</ListItem>
              <ListItem>Maskinstyrning</ListItem>
              <ListItem>Volymberäkning</ListItem>
              <ListItem>Projekteringsmätning</ListItem>
              <ListItem>Kontrollmätning</ListItem>
              <ListItem>Precisionsavvägning</ListItem>
              <ListItem>Byggnät</ListItem>
              <ListItem>Lägeskontroll</ListItem>
            </List>
          </Content>
        </BlockContent>
      </Grid>
      <Grid id="kontakt">
        <GeoMap />
        <BlockContent bgColor="#d6d6d6" color="black">
          <Content>
            <h3>Du hittar oss här</h3>
            <Span icon={AdressIcon}>
              <strong>Hallsbergsgatan 3</strong> <br />
              124 70 Bandhagen
            </Span>
            <br />
            <Span icon={MailIcon}>
              <a href="mailto:info@geometer.se">info@geometer.se</a>
            </Span>
            <br />
            <Span icon={PhoneIcon}>
              <a href="tel:+46704307771">+46 70 430 77 71</a>
            </Span>
          </Content>
        </BlockContent>
      </Grid>
    </div>
  </Section>
);

export default SquareSection;
