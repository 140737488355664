
import React from 'react';
import { Helmet } from 'react-helmet';
// import { GlobalStyle } from '../style/globalStyle';
// import Theme from '../theme/theme';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import AboutSection from '../sections/AboutSection/AboutSection';
import SquareSection from '../sections/SquareSection/SquareSection';
import Footer from '../components/Footer/Footer';

export default () => (
  <>
    <h1 className="visually-hidden">Geometer</h1>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
      />
      <title>Geometer</title>
      <meta name="robots" content="index, follow" />
      <meta name="description" content="We measure your stuff" />
      <meta property="og:site_name" content="Geometer" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Geometer" />
      <meta property="og:description" content="We measure your stuff" />
      <meta property="og:url" content="https://www.geometer.se/" />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color</meta>" content="#ffffff" />
    </Helmet>
    <Header />
    <Hero />
    <AboutSection />
    <SquareSection />
    <Footer />
  </>
);
