import React, { useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../../theme/BreakPoints';
import NavBar from '../NavBar/NavBar';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: block;
  transition: all 0.3s ease-in;
  margin: 0 auto;
  padding: 0.5rem 0;
  background-color: ${({ theme: { colors } }) => colors.white};

  @media ${device.laptop} {
    padding: 1rem 0;

    &.scrolled {
      box-shadow: 0 1px 2px rgba(195, 195, 195, 0.7);
      padding: 0.5rem 0;

      & nav a {
        color: ${({ theme: { colors } }) => colors.nightRider};
      }

      & div a img {
        height: 35px;
      }
    }
  }
`;

function Header() {
  useEffect(() => {
    const header = document.querySelector('header');
    const offset = 200;
    const stickyHeader = window.addEventListener('scroll', () => {
      if (window.pageYOffset > offset) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    });
    return () => {
      window.removeEventListener('scroll', stickyHeader);
    };
  }, []);

  return (
    <HeaderContainer>
      <NavBar />
    </HeaderContainer>
  );
}

export default Header;
