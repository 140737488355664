import React from 'react';
import styled from 'styled-components';
import { device } from '../../theme/BreakPoints';
import Logo from '../../images/logo.svg';
// import Theme from '../../theme/Theme';

const FooterUpper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  padding: 4rem 0;
  border-bottom: 1px solid rgba(142, 142, 142, 0.7);
  color: ${({ theme: { colors } }) => colors.pitchBlack};

  h3 {
    margin: 0 0 1.05rem;
  }
`;

const FooterLower = styled.div`
  background-color: ${({ theme: { colors } }) => colors.haveLockBlue};
  padding: 1.5rem 0;
  color: ${({ theme: { colors } }) => colors.white};
`;

const FooterLowerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: 100%;

  @media ${device.laptop} {
    grid-template-columns: 50% 50%;
    grid-column-gap: 6rem;
  }
`;

const LogoImage = styled.img`
  height: 3rem;
`;

const Adress = styled.div`
  position: relative;
  display: block;

  & span {
    display: block;

    a {
      color: ${({ theme: { colors } }) => colors.pitchBlack};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Footer = () => (
  <>
    <FooterUpper>
      <div className="container">
        <Flex>
          <div>
            <LogoImage src={Logo} alt="Logo" />
            <p>
              För att vi ska kunna utvecklas ställer vi alltid höga krav på både personal och utrustning. Vi gör alltid
              vårt bästa och sätter kunden och uppdraget i fokus. Välkommen att höra av dig till oss om du vill veta
              mer.
            </p>
          </div>
          <div>
            <h3>Kontakta oss:</h3>
            <Adress>
              <span>Hallbergsgatan 3</span>
              <span>124 70 Bandhagen</span>
              <span>
                <a href="mailto:info@geometer.se">info@geometer.se</a>
              </span>
              <span>
                <a href="tel:+46704307771">+46 70 430 77 71</a>
              </span>
            </Adress>
          </div>
        </Flex>
      </div>
    </FooterUpper>
    <FooterLower>
      <div className="container">
        <FooterLowerContent>
          <div>&copy;&nbsp;{new Date().getFullYear()}&nbsp;Geometer&nbsp;All Rights Reserved.</div>
        </FooterLowerContent>
      </div>
    </FooterLower>
  </>
);

export default Footer;
