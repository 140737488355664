import React, { Component } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

class GeoMap extends Component {
  constructor() {
    super();
    this.state = {
      lat: 59.2664168,
      lng: 18.0145327,
      zoom: 13,
    };
  }

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <Map center={position} zoom={this.state.zoom}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            Du hittar oss här! <br />
            Fyrfartsvägen 59
          </Popup>
        </Marker>
      </Map>
    );
  }
}

export default GeoMap;
