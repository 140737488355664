import React from 'react';
import styled from 'styled-components';
import { device } from '../../theme/BreakPoints';
import hero from '../../images/hero.jpg';

const BackgroundImage = styled.section`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%),
    url(${hero});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 162px;
  width: 100%;
  margin-top: 3.8rem;

  @media ${device.mobileM} {
    height: 192px;
  }

  @media ${device.tablet} {
    height: 390px;
  }

  @media ${device.laptop} {
    background-size: cover;
    height: 80vh;
    margin-top: 0;
  }
`;

const HeroContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .container {
    text-align: center;
  }
`;

const HeroTitle = styled.h1`
  color: white;
  font-size: 2.7rem;

  @media ${device.tablet} {
    font-size: 3rem;
  }
`;

const HeroSubTitle = styled.span`
  color: white;
  font-size: 1.7rem;
`;

const Hero = () => (
  <>
    <BackgroundImage>
      <HeroContent id="hero">
        <div className="container">
          <HeroTitle />
          <HeroSubTitle />
        </div>
      </HeroContent>
    </BackgroundImage>
  </>
);

export default Hero;
